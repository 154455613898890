import React, { ChangeEvent, useContext, useEffect, useState, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty, isNil } from 'ramda';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import Form from 'forms/CanvasConfigurationForm';
import getHooksForRepository from 'hooks/getHooksForRepository';
import IntegrationConfigurationsRepository from 'repositories/IntegrationConfigurationsRepository';
import IntegrationsContext from 'components/IntegrationsPanel/IntegrationsContext';
import { addErrorNotification, addSuccessNotification } from 'sharedContainers/Notifications/NotificationsActions';
import ToggleButtonGroup from 'components/ToggleButtonGroup';
import ToggleButton from 'components/ToggleButton';
import Typography from 'components/Typography';
import Icon from 'components/Icon';
import { ConfigurationTableModalProps, Option } from './types';
import {
  ConfigurationField,
  ConfigurationSearchableSelect,
  ConfigurationSelect,
  ConfigurationTextArea,
} from './components/InputComponents';
import { findPlacements, findServices, filterServices, findService } from './utils';

import useStyles from './useStyles';

const jsonPlaceholder = { name: 'John Doe', age: 30, email: 'john.doe@example.com' };
const configOptions = [
  { key: 'lti_11_url', value: '1.1 From a Link' },
  { key: 'lti_11_configuration', value: '1.1 Paste XML' },
  { key: 'lti_13_url', value: '1.3 JSON URL' },
  { key: 'lti_13_configuration', value: '1.3 by Paste JSON Code' },
  { key: 'lti_13_dynamic_registration', value: '1.3 Dynamic Registration' },
];

const sysAdminOnlyconfigOptions = [{ key: 'lti_13_global_inherited_key', value: '1.3 Global Inherited Key' }];

const initialForm = {
  integrationType: 'lti_13_dynamic_registration',
  authLink: '',
  authRequired: '',
  configuration: '',
  url: '',
  ltiPlacementsIds: [],
  name: '',
  ltiServicesIds: [{ id: 4, name: 'Dynamic Registration 1.0' }],
  keyName: '',
  ownerEmail: '',
  notes: '',
};

const { useCreate, useUpdate } = getHooksForRepository(IntegrationConfigurationsRepository);

const ConfigurationTableModal = ({ onClose, formValue }: ConfigurationTableModalProps) => {
  const classes = useStyles();

  const [form, setForm] = useState(() => Form.defaultAttributes(initialForm));
  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch();

  const { toolId, relatedData, isSysAdmin } = useContext(IntegrationsContext);

  const dynamicReg = useMemo(() => {
    if (!relatedData) return null;
    return findService(relatedData?.ltiServices, 'Dynamic Registration 1.0');
  }, [relatedData]);

  const filteredLtiServices = useMemo(() => {
    if (!relatedData) return [];
    return filterServices(relatedData?.ltiServices, dynamicReg ? [dynamicReg?.id] : []);
  }, [relatedData]);

  const updatedFormValue = !isEmpty(formValue) &&
    relatedData && {
      ...formValue,
      ltiPlacementsIds: findPlacements(relatedData?.ltiPlacements, formValue.ltiPlacementsIds),
      ltiServicesIds: findServices(relatedData?.ltiServices, formValue.ltiServicesIds),
    };

  useEffect(() => {
    if (isEmpty(formValue)) return;
    setForm(updatedFormValue);
  }, [formValue, relatedData]);

  useEffect(() => {
    // if form is new and integration type is dynamic registration, set the dynamic registration service
    if (integrationType === 'lti_13_dynamic_registration') {
      setForm(prevForm => {
        if (!prevForm.id && prevForm.integrationType === 'lti_13_dynamic_registration') {
          return { ...prevForm, ltiServicesIds: dynamicReg ? [dynamicReg] : prevForm.ltiServicesIds };
        }
        return { ...prevForm };
      });
    }
  }, [form.integrationType, relatedData]);

  const { mutate: create, isLoading } = useCreate({
    onSuccess() {
      dispatch(addSuccessNotification('Resource created successfully'));
      onClose();
    },
    onError() {
      dispatch(addErrorNotification('Error creating resource'));
    },
  });

  const { mutate: update, isLoading: updateLoading } = useUpdate({
    onSuccess() {
      dispatch(addSuccessNotification('Resource updated successfully'));
      onClose();
    },
    onError() {
      dispatch(addErrorNotification('Error updating resource'));
    },
  });

  const handleChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({}); // Clear errors on change
    setForm({ ...form, [field]: e.target.value });
  };

  const handleSelect = (field: string) => (value: string | Option[]) => {
    setErrors({}); // Clear errors on change
    if (field === 'integrationType') {
      setForm({ ...initialForm, integrationType: value.toString() }); // This ensures that form get cleared to default when the integration type changes.
    } else {
      setForm({ ...form, [field]: value });
    }
  };

  const handleToggle = (field: string) => event => {
    setForm({ ...form, [field]: event.currentTarget.value });
  };

  const handleSubmit = () => {
    const formErrors = Form.validate(form);
    const attr = Form.attributesToSubmit(form);
    if (isEmpty(formErrors)) {
      isEmpty(formValue) ? create(toolId, attr) : update(toolId, formValue.id, attr);
    } else {
      setErrors(formErrors);
    }
  };

  let buttonText;
  if (isLoading || updateLoading) {
    buttonText = 'Loading...';
  } else if (isEmpty(formValue)) {
    buttonText = 'Create';
  } else {
    buttonText = 'Update';
  }

  const {
    integrationType,
    authLink,
    authRequired,
    configuration,
    globalInheritedKey,
    url,
    ltiPlacementsIds,
    name,
    ltiServicesIds,
    keyName,
    ownerEmail,
    notes,
  } = form;

  const renderNameSection = (isRequired = true) => (
    <ConfigurationField
      label="Name"
      value={name}
      onChange={handleChange('name')}
      placeholder="Name to uniquely identify the configuration"
      id="name-field"
      error={errors.name}
      required={isRequired}
    />
  );

  const renderAuthSection = () => (
    <>
      <ConfigurationField
        label="Auth Link"
        value={authLink}
        onChange={handleChange('authLink')}
        placeholder="Auth Link"
        id="auth-link-field"
      />
      <div>
        <Grid item xs={12}>
          <Typography>
            Auth Required <Icon icon="inst-info" width={12} height={12} />
          </Typography>
          <ToggleButtonGroup
            value={authRequired.toString()}
            exclusive
            onChange={handleToggle('authRequired')}
            aria-label="text alignment"
            data-node="auth-required-toggle"
          >
            <ToggleButton value="true" aria-label="Required">
              Required
            </ToggleButton>
            <ToggleButton value="false" aria-label="Not Required">
              Not Required
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </div>
    </>
  );

  const renderUrlSection = (label: string) => (
    <ConfigurationField
      label={label}
      value={url}
      onChange={handleChange('url')}
      placeholder="https://www.exampleurl.com"
      id="url-field"
      error={!isNil(errors.url)}
      required
    />
  );

  const renderKeyNameSection = () => (
    <>
      <ConfigurationField
        label="Key Name"
        value={keyName}
        onChange={handleChange('keyName')}
        placeholder="Key Name"
        id="key-name-field"
        error={errors.keyName}
        required
      />
      <ConfigurationField
        label="Owner Email"
        value={ownerEmail}
        onChange={handleChange('ownerEmail')}
        placeholder="owner.example@email.com"
        id="owner-email-field"
        error={errors.ownerEmail}
        required={integrationType === 'lti_13_url'}
      />
    </>
  );

  const renderPlacementsSection = () => (
    <ConfigurationSearchableSelect
      label="Placements"
      value={ltiPlacementsIds}
      onChange={handleSelect('ltiPlacementsIds')}
      options={relatedData?.ltiPlacements}
      name="ltiPlacementsIds"
      id="lti-placements-field"
      placeholder="Make Selection"
    />
  );

  const renderServicesSection = () => (
    <>
      <ConfigurationSearchableSelect
        label="Services"
        value={ltiServicesIds}
        onChange={handleSelect('ltiServicesIds')}
        options={integrationType === 'lti_13_dynamic_registration' ? relatedData?.ltiServices : filteredLtiServices}
        name="ltiServicesIds"
        id="lti-services-field"
        placeholder="Check all that apply"
      />
      <Grid item xs={12}>
        <ConfigurationTextArea
          label="Notes"
          value={notes}
          onChange={handleChange('notes')}
          placeholder="Notes about the configuration."
          id="note-field"
          error={false}
          // helperText="Required Field *"
        />
      </Grid>
    </>
  );

  const typeToComponentMap = {
    lti_11_url: () => (
      <>
        {renderNameSection()}
        {renderUrlSection('Config URL')}
        {renderAuthSection()}
        {renderPlacementsSection()}
      </>
    ),
    lti_11_configuration: () => (
      <>
        {renderNameSection()}
        <ConfigurationField
          label="XML Configuration"
          value={configuration}
          onChange={handleChange('configuration')}
          placeholder="https://www.exampleurl.com/xml"
          id="xml-configuration-field"
          error={errors.configuration}
          required
        />
        {renderAuthSection()}
        {renderPlacementsSection()}
      </>
    ),
    lti_13_url: () => (
      <>
        {renderNameSection(false)}
        {renderUrlSection('JSON URL')}
        {renderKeyNameSection()}
        {renderPlacementsSection()}
        {renderServicesSection()}
      </>
    ),
    lti_13_configuration: () => (
      <>
        {renderNameSection(false)}
        <ConfigurationTextArea
          label="Paste JSON Code"
          value={configuration}
          onChange={handleChange('configuration')}
          placeholder={JSON.stringify(jsonPlaceholder, null, 2)}
          id="lti-configuration-field"
          error={errors.configuration}
          required
        />
        {renderKeyNameSection()}
        {renderPlacementsSection()}
        {renderServicesSection()}
      </>
    ),
    lti_13_dynamic_registration: () => (
      <>
        {renderNameSection(false)}
        {renderUrlSection('Dynamic Registration URL')}
        {renderPlacementsSection()}
        {renderServicesSection()}
      </>
    ),
    lti_13_global_inherited_key: () => (
      <>
        {renderNameSection(false)}
        <ConfigurationTextArea
          label="Client ID"
          value={globalInheritedKey}
          onChange={handleChange('globalInheritedKey')}
          placeholder="123456789"
          id="lti-client-id-field"
          error={errors.globalInheritedKey}
          required
        />
        {renderPlacementsSection()}
        {renderServicesSection()}
      </>
    ),
  };

  const renderComponentsForType = typeToComponentMap[integrationType] || (() => null);

  return (
    <div className={classes.modalContent}>
      <Grid container spacing={2}>
        <ConfigurationSelect
          label="Canvas Configuration"
          value={integrationType}
          onChange={handleSelect('integrationType')}
          options={[...configOptions, ...(isSysAdmin ? sysAdminOnlyconfigOptions : [])]}
          name="integrationType"
          id="canvas-configuration-select"
        />
        {renderComponentsForType()}
      </Grid>

      <div className={classes.actions}>
        <Button
          className={classes.closeBtn}
          onClick={onClose}
          variant="contained"
          color="bordered"
          size="small"
          data-node="cancel-integration-configuration-button"
        >
          Cancel
        </Button>
        <Button
          className={classes.addConfigBtn}
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          size="small"
          disabled={isLoading}
          data-node="create-integration-configuration-button"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default ConfigurationTableModal;
