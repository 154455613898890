import React from 'react';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';
import { Button } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';

import * as Routes from 'Routes';
import { SentPasswordProps } from './types';

const SentForgotPassword = ({ email }: SentPasswordProps) => {
  return (
    <>
      <View as="div" margin="medium none none">
        <Flex display="flex" direction="column">
          <View as="div" margin="none none large">
            <Text size="x-large" weight="bold">
              Reset Email has been sent!
            </Text>
          </View>
          <View as="div" margin="none none large">
            <Text size="medium" weight="bold">
              {email.email}
            </Text>
          </View>
        </Flex>

        <View as="div">
          <View as="div" height="70vh">
            <Text size="medium" weight="normal" lineHeight="default">
              An email has been sent to you that contains a link to reset your password. Please follow the instructions
              contained in the email to login to your account.
            </Text>
          </View>
          <View as="div">
            <Flex display="flex" justifyItems="space-between" margin="none xx-small">
              <Button href={Routes.new_user_password_path()} color="primary-inverse">
                Back to Forgot Password
              </Button>
              <Button
                href={Routes.new_user_session_path()}
                color="primary"
                interaction="enabled"
                data-node="reset_password_button"
              >
                Back to Login
              </Button>
            </Flex>
          </View>
        </View>
      </View>
    </>
  );
};

export default SentForgotPassword;
