import React from 'react';
import PropTypes from 'prop-types';
import { ApplyTheme } from '@instructure/ui-themeable';
import { Text } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import { Button } from '@instructure/ui-buttons';
import { useResponsiveContext } from 'hooks/useResponsiveContext';

const Role = ({ id, title, onSelect, selected, disabled = false }) => {
  const { isMobile } = useResponsiveContext();
  const buttonWidth = isMobile ? '6em' : '7em';
  return (
    <ApplyTheme
      theme={{
        [Button.theme]: {
          primaryBackground: 'white',
          primaryHoverBackground: 'white',
          primaryColor: 'licorice',
          secondaryBackground: 'white',
          secondaryBorderColor: 'rgb(3,116,181)', // brand wasn't working here..
          borderWidth: selected ? '0.25rem' : '0.0625rem',
          borderRadius: '0.5rem',
        },
      }}
    >
      <Button
        color={selected ? 'secondary' : 'primary'}
        onClick={() => {
          onSelect(id);
        }}
        data-node={`select_${id}_role`}
        disabled={disabled}
        margin="xx-small"
      >
        <View as="div" width={buttonWidth} padding="small none small none">
          <Text size="medium" weight="bold">
            {title}
          </Text>
        </View>
      </Button>
    </ApplyTheme>
  );
};

Role.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
};

export default Role;
